import React, {useRef} from 'react';
import emblema from '../media/Эмблема.png';

export const About=()=>{

    const mainref=useRef();

    const showMain = () => {
        if(window.scrollY>window.innerHeight){
            mainref.current.style.marginTop="5.5vw";
        }else{
            mainref.current.style.marginTop="0vw";
            
        }
    }
    window.addEventListener('scroll', showMain)


    return(
        <div ref={mainref} className='aboutslide' id="aboutslide">
            <a name='about_company'>
            <div className='aboutnames'>
            <img src={emblema} alt=''/>
            <div className='namecompany'>
            <div className='namecompany2'>
                <span className='chop'>Частное охранное предприятие <p className="nameC">“ОЛИМП”</p></span>
                {/* <div className='palka'></div> */}
                {/* <span className='ooo'>ООО <p className="nameC">“ОЛИМП - СЕРВИС”</p></span> */}
            </div>
            <h3>Наши системы безопасности разработаны с использованием новейших технологий, чтобы обеспечить высочайший уровень защиты вашей собственности. </h3>
            </div>
            </div>
            <div className='advantages'>
                <div className='advantage client'>
                <i class="fa-solid fa-user-tie fa-5x"/>
                    <h4>Индивидуальный подход к каждому клиенту</h4>
                    <h6>Мы тесно сотрудничаем с нашими клиентами, чтобы понять их проблемы безопасности и предоставить индивидуальное решение по безопасности, отвечающее их потребностям.</h6>
                </div>
                <div className='palka palka2'></div>

                <div className='advantage years'>
                <i class="fa-solid fa-hourglass-start fa-5x"></i>
                    <h4>Более 13 лет на страже безопасности</h4>
                    <h6>Наши системы безопасности предназначены для обеспечения многолетнего надежного обслуживания, давая вам уверенность в том, что ваша собственность всегда защищена.</h6>
                </div>
                <div className='palka palka2'></div>

                <div className='advantage staff'>
                <i class="fa-regular fa-address-card fa-5x"></i>                    <h4>Высококвалифицированный и лицензированный персонал</h4>
                    <h6>Мы предлагаем комплексное обучение и поддержку, чтобы наши клиенты получили максимальную отдачу от своих систем безопасности.</h6>
                </div>
            </div>
            </a>
        </div>
    )
}
