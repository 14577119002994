import React, { useState, useRef } from 'react';
import emblema from '../media/Эмблема.png';


export const Header=()=>{
    // const [navbar,setHeader]=useState(false);
    const headerref=useRef();

    const showHeader = () => {
        if(window.scrollY>window.innerHeight){
            headerref.current.style.position="fixed";
            headerref.current.style.width="100%"
            headerref.current.style.animationName="show_head";  
            headerref.current.style.animationDuration="1s";                  
            headerref.current.children[0].style.display="block";
            headerref.current.children[1].children[0].addEventListener('click', ()=>{
                document.body.scrollTop=0;
                document.documentElement.scrollTop = 0;
            });
        }else{
            headerref.current.style.position="relative";
            headerref.current.children[0].style.display="none";
            
        }
    }

    window.addEventListener('scroll', showHeader)

        // const [headerref,isinViewHeader]=useInView({
        //     rootMargin:"-8%"
        // })

    // const isinView=useInView(refff,{ once:true });

        // const headeranimation=useAnimation();

        // useEffect(()=>{
        //     if(isinViewHeader){
        //         headeranimation.start({
        //             position:'fixed',
        //             width:"100%",
        //             animationName:"show_head",
        //             animationDuration:"1s"
        //         }, console.log(11))
        //     }
        // },[isinViewHeader])


    // window.onscroll = function(){
        
    //      var html = document.documentElement, body = document.body;
    //      var heightBody=html.offsetHeight;
    //      var nav = document.getElementById('navbar').style;
    //      var logo = document.getElementById('logo').style;
    //      var aboutslide=document.getElementById('aboutslide').style;
    //      var des=document.getElementById('des').style;
    //      var des1=document.getElementById('des1').style;
    //      var des2=document.getElementById('des2').style;
    //      var des3=document.getElementById('des3').style;
    //      var des4=document.getElementById('des4').style;
        //  var des5=document.getElementById('des5');
    //      var cloudShield=document.querySelector(".CloudShield").style;
    //      var cloudShield2=document.querySelector(".CloudShield2").style;
    //      var calculator=document.querySelector(".calc").style;
    //      var PartnersLetterText=document.querySelector(".PartnersLetterText").style;


    //      if(html.scrollTop>=0.73*heightBody||body.scrollTop>=0.73*heightBody){
    //         document.querySelector(".PartnersLetterText img").style.display="block";

    //      } else if(html.scrollTop>=0.68*heightBody||body.scrollTop>=0.68*heightBody){
    //         PartnersLetterText.marginTop="10%"
    //         calculator.animationName='Calc_Show';
    //         calculator.animationDuration='2s';
    //         calculator.display="block";

    //      }else if(html.scrollTop>=0.35*heightBody||body.scrollTop>=0.35*heightBody){
    //         cloudShield2.display="block";
    //      }else if(html.scrollTop>=0.265*heightBody||body.scrollTop>=0.265*heightBody){
    //         des.animationName='left_table';
    //         des.animationDuration='2s';
    //         des.display='block';
    //         des1.animationName='left_table';
    //         des1.animationDuration='2s';
    //         des1.display='block';
    //         des2.animationName='left_table';
    //         des2.animationDuration='2s';
    //         des2.display='block';
    //         des3.animationName='left_table';
    //         des3.animationDuration='2s';
    //         des3.display='block';
    //         des4.animationName='left_table';
    //         des4.animationDuration='2s';
    //         des4.display='block';
            // des5.style.verticalAlign='left_table';
    //         des5.animationDuration='2s';
    //         des5.display='block';

    //     }else if(html.scrollTop>=0.22*heightBody||body.scrollTop>=0.22*heightBody){
    //         cloudShield.display="block";

    //     } else if(html.scrollTop>=0.15*heightBody||body.scrollTop>=0.15*heightBody) {

    //         //  nav.display='flex';
    //          nav.position='fixed';
    //          nav.width="100%";
    //          nav.animationName="show_head";
    //          nav.animationDuration="1s";
    //          logo.display='block';
    //          aboutslide.marginTop='5.5vw';
             

        
    //      }else if(html.scrollTop<0.15*heightBody||body.scrollTop<0.15*heightBody){
    //         nav.position='relative';
    //         logo.display='none';
    //         aboutslide.marginTop='0px';
    //     };
        // headerref.current.children[1].children[0].addEventListener('click', ()=>{
        //     document.body.scrollTop=0;
        //     document.documentElement.scrollTop = 0;
        // });
        // document.querySelector('.menu__item2').addEventListener('click', ()=>{
        //     document.body.scrollTop=0;
        //     document.documentElement.scrollTop = 0;
        // });
        
    // }
        
    return(
        <div ref={headerref} className="navbar" id='navbar' >

            <div className="logo" id='logo'>
                <img src={emblema} alt="" srcset="" />
            </div>

            <ul className="menu">
                <li className="menu__item menu__item1"><a href="#main">Главная</a></li>
                <li className="menu__item"><a href="#about_company">О компании</a></li>
                <li className="menu__item"><a href="#services">Услуги</a></li>
                <li className="menu__item"><a href="#calc">Калькулятор цен</a></li>
                <li className="menu__item"><a href="#vacations">Вакансии</a></li>
                <li className="menu__item"><a href="#prom">Акции</a></li>
                <li className="menu__item"><a href="#contacts">Контакты</a></li>
            </ul>

        </div>
    )
};


export default Header;