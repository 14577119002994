import React from 'react';
import emblema from '../media/Эмблема.png';
import passage from '../media/passage.jpg';

import { YMaps, Map } from '@pbe/react-yandex-maps';





export const Footer=()=>{

    
    document.onanimationstart=function(){
        var Fiz=document.getElementById("Fiz");
        var Teh=document.getElementById("Teh");
        var Pult=document.getElementById("Pult");
        var Econom=document.getElementById("Econom");
        if(1+1==2){
            console.log(22)
        }
        var href1=document.querySelector(".footerhref1");
        href1.addEventListener('click', ()=>{
            Fiz.current.style.display="block";
            Fiz.current.style.animationName="Show_Des";
            Fiz.current.style.animationDuration="2s";
            Pult.current.style.display="none";
            Teh.current.style.display="none";
            Econom.current.style.display="none";
        })
    }
    return(

        
        // <div className="footer1" >
            <div className='footer'>
            <div className="stolb emblema">
                <div className="olympic">
                    <img src={emblema} alt="" srcset="" />
                    <div className='footername'>
                    <h4>Частное Охранное<br/> Предприятие</h4>
                    <h2>"Олимп"</h2>
                    </div>
                </div>
                <h6>2023 Все права защищены</h6>
                <h6>Политика конфиденциальности</h6>
            </div>
            <div className="stolb stolb1">
                <h3>УСЛУГИ</h3>
                <h5><a className='footerhref1' href='#Fiz' >ФИЗИЧЕСКАЯ ОХРАНА</a></h5>
                {/* <h5><a className='footerhref2' href='#Teh'>ТЕХНИЧЕСКИЕ СРЕДСТВА БЕЗОПАСНОСТИ</a></h5> */}
                <h5><a className='footerhref3' href='#Pult'>ПУЛЬТОВАЯ ОХРАНА</a></h5>
                <h5><a className='footerhref4' href='#Econom'>ЭКОНОМИЧЕСКАЯ БЕЗОПАСНОСТЬ ОРГАНИЗАЦИЙ</a></h5>
            </div>
            <div className="stolb stolb1">
                <h3>ИНФОРМАЦИЯ</h3>
                <h5><a className='.menu__item1' href="#">ГЛАВНАЯ</a></h5>
                <h5><a href="#about_company">О КОМПАНИИ</a></h5>
                <h5><a href="#services">УСЛУГИ</a></h5>
                <h5><a href="#vacations">ВАКАНСИИ</a></h5>
                <h5><a href="#calc">КАЛЬКУЛЯТОР ЦЕН</a></h5>
                <h5><a href="#contacts">КОНТАКТЫ</a></h5>
            </div>
            <div className="stolb">
                <h3>ОСТАВАЙТЕСЬ НА СВЯЗИ</h3>
                <h6 className='f3'><i class="fa-solid fa-map-location-dot"/> 423452,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; г.Альметьевск,<br/> проспект Габдуллы Тукая, 41Б</h6>
                <h5 className='f3'><i class="fa-solid fa-phone"></i> +7 (8553) 44-19-09</h5>
                <h5 className='f3'><i class="fa-solid fa-phone"></i> +7 (8553) 44-18-08 (ПЦН)</h5>
                <h5 className='f3'><i class="fa-solid fa-phone"></i> +7 962 569-61-62</h5>
                <h5 className='f3'><i class="fa-solid fa-envelope"></i> chop.olimp@mail.ru</h5>
            </div>
            <div className="passage">
            <iframe className='passageimg' src="https://yandex.ru/map-widget/v1/?um=constructor%3A972289620c4c0388d144ca0425c9e256a06cc866a28dbb5dcbdc21da0d6ba4c8&amp;source=constructor" frameborder="0"></iframe>
            </div>
        </div>
    )
}

