import React from 'react';
import { motion} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';



export const Calc=()=>{

    const [refCalc,isinViewCalc]=useInView({
        rootMargin:"-26%"
    })

    // const refff = useRef()
    // const isinView=useInView(refff,{ once:true });

    const calcanimation=useAnimation();

    useEffect(()=>{
        if(isinViewCalc){
            calcanimation.start({
                animationName:"Calc_Show",
                animationDuration:"1.6s",
                display:"block"
            })
        }
    },[isinViewCalc])

        var usluga=0;
        var countGuard=0;
        var variation=0;
        var modeSec=0;

        var usluga1=0;
        var countGuard1=0;
        var modeSec1=0;
    document.onanimationstart=function(){

        var checkboxes = document.querySelectorAll("[name=cb]");
        var checkboxes1 = document.querySelectorAll("[name=pt]");
        var checkboxes2 = document.querySelectorAll("[name=vn]");
        var checkboxes3 = document.querySelectorAll("[name=ms]");

        var checkboxes4 = document.querySelectorAll("[name=cb1]");
        var checkboxes5 = document.querySelectorAll("[name=pt1]");
        var checkboxes6 = document.querySelectorAll("[name=ms1]");




        var calcPult =document.querySelector(".CalcPult");
        var calcPulth3 =document.querySelector(".CalcPult h3");

        var calcFiz =document.querySelector(".CalcFiz");
        var calcFizh3 =document.querySelector(".CalcFiz h3");


        calcPult.addEventListener('click', ()=>{
            pultinfo.style.display="block";
            calcPulth3.style.backgroundColor="#5176fe";
            calcPulth3.style.color="white";
            calcFizh3.style.color="black";
            calcFizh3.style.backgroundColor="";
            fizinfo.style.display="none";

        });
        calcFiz.addEventListener('click', ()=>{
            fizinfo.style.display="block";
            calcFizh3.style.backgroundColor="#5176fe";
            calcFizh3.style.color="white";
            calcPulth3.style.color="black";
            calcPulth3.style.backgroundColor="";
            pultinfo.style.display="none";
        });
        
        var pultinfo=document.getElementById("D228");
        var fizinfo=document.getElementById("D229");



        for (let checkbox of checkboxes) checkbox.onchange = checkbox_change;
 
        function checkbox_change() {
            if (this.checked && document.querySelectorAll("[name=cb]:checked").length > 1)
                this.checked = false;
        }


        function Total(){
            var total=document.querySelector(".Total");
            var total1=document.querySelector(".Total1");


            if(variation===1 && countGuard===1 && modeSec===1 && usluga===1){total.innerHTML='165 500';}
            if(variation===1 && countGuard===1 && modeSec===1 && usluga===2){total.innerHTML='170 500';}
            if(variation===1 && countGuard===1 && modeSec===1 && usluga===3){total.innerHTML='175 600';}
            if(variation===1 && countGuard===1 && modeSec===1 && usluga===4){total.innerHTML='179 300';}
            if(variation===1 && countGuard===1 && modeSec===2 && usluga===1){total.innerHTML='89 500';}
            if(variation===1 && countGuard===1 && modeSec===2 && usluga===2){total.innerHTML='92 500';}
            if(variation===1 && countGuard===1 && modeSec===2 && usluga===3){total.innerHTML='95 000';}
            if(variation===1 && countGuard===1 && modeSec===2 && usluga===4){total.innerHTML='99 500';}
            if(variation===1 && countGuard===2 && modeSec===1 && usluga===1){total.innerHTML='160 300';}
            if(variation===1 && countGuard===2 && modeSec===1 && usluga===2){total.innerHTML='165 100';}
            if(variation===1 && countGuard===2 && modeSec===1 && usluga===3){total.innerHTML='170 000';}
            if(variation===1 && countGuard===2 && modeSec===1 && usluga===4){total.innerHTML='173 000';}
            if(variation===1 && countGuard===2 && modeSec===2 && usluga===1){total.innerHTML='87 900';}
            if(variation===1 && countGuard===2 && modeSec===2 && usluga===2){total.innerHTML='90 500';}
            if(variation===1 && countGuard===2 && modeSec===2 && usluga===3){total.innerHTML='93 200';}
            if(variation===1 && countGuard===2 && modeSec===2 && usluga===4){total.innerHTML='94 500';}
            if(variation===1 && countGuard===3 && modeSec===1 && usluga===1){total.innerHTML='157 000';}
            if(variation===1 && countGuard===3 && modeSec===1 && usluga===2){total.innerHTML='160 200';}
            if(variation===1 && countGuard===3 && modeSec===1 && usluga===3){total.innerHTML='165 000';}
            if(variation===1 && countGuard===3 && modeSec===1 && usluga===4){total.innerHTML='167 000';}
            if(variation===1 && countGuard===3 && modeSec===2 && usluga===1){total.innerHTML='85 200';}
            if(variation===1 && countGuard===3 && modeSec===2 && usluga===2){total.innerHTML='87 700';}
            if(variation===1 && countGuard===3 && modeSec===2 && usluga===3){total.innerHTML='90 300';}
            if(variation===1 && countGuard===3 && modeSec===2 && usluga===4){total.innerHTML='92 500';}

            if(variation===2 && countGuard===1 && modeSec===1 && usluga===2){total.innerHTML='170 465';}
            if(variation===2 && countGuard===1 && modeSec===1 && usluga===1){total.innerHTML='175 500';}
            if(variation===2 && countGuard===1 && modeSec===1 && usluga===3){total.innerHTML='180 800';}
            if(variation===2 && countGuard===1 && modeSec===1 && usluga===4){total.innerHTML='183 500';}
            if(variation===2 && countGuard===1 && modeSec===2 && usluga===1){total.innerHTML='92 185';}
            if(variation===2 && countGuard===1 && modeSec===2 && usluga===2){total.innerHTML='94 900';}
            if(variation===2 && countGuard===1 && modeSec===2 && usluga===3){total.innerHTML='97 700';}
            if(variation===2 && countGuard===1 && modeSec===2 && usluga===4){total.innerHTML='101 200';}
            if(variation===2 && countGuard===2 && modeSec===1 && usluga===1){total.innerHTML='165 300';}
            if(variation===2 && countGuard===2 && modeSec===1 && usluga===2){total.innerHTML='170 250';}
            if(variation===2 && countGuard===2 && modeSec===1 && usluga===3){total.innerHTML='175 400';}
            if(variation===2 && countGuard===2 && modeSec===1 && usluga===4){total.innerHTML='177 500';}
            if(variation===2 && countGuard===2 && modeSec===2 && usluga===1){total.innerHTML='89 500';}
            if(variation===2 && countGuard===2 && modeSec===2 && usluga===2){total.innerHTML='92 150';}
            if(variation===2 && countGuard===2 && modeSec===2 && usluga===3){total.innerHTML='94 950';}
            if(variation===2 && countGuard===2 && modeSec===2 && usluga===4){total.innerHTML='95 450';}
            if(variation===2 && countGuard===3 && modeSec===1 && usluga===1){total.innerHTML='160 500';}
            if(variation===2 && countGuard===3 && modeSec===1 && usluga===2){total.innerHTML='165 500';}
            if(variation===2 && countGuard===3 && modeSec===1 && usluga===3){total.innerHTML='170 500';}
            if(variation===2 && countGuard===3 && modeSec===1 && usluga===4){total.innerHTML='173 850';}
            if(variation===2 && countGuard===3 && modeSec===2 && usluga===1){total.innerHTML='87 000';}
            if(variation===2 && countGuard===3 && modeSec===2 && usluga===2){total.innerHTML='89 610';}
            if(variation===2 && countGuard===3 && modeSec===2 && usluga===3){total.innerHTML='92 300';}
            if(variation===2 && countGuard===3 && modeSec===2 && usluga===4){total.innerHTML='94 300';}

            if(variation===3 && countGuard===1 && modeSec===1 && usluga===2){total.innerHTML='180 000';}
            if(variation===3 && countGuard===1 && modeSec===1 && usluga===1){total.innerHTML='185 400';}
            if(variation===3 && countGuard===1 && modeSec===1 && usluga===3){total.innerHTML='190 950';}
            if(variation===3 && countGuard===1 && modeSec===1 && usluga===4){total.innerHTML='193 500';}
            if(variation===3 && countGuard===1 && modeSec===2 && usluga===1){total.innerHTML='97 500';}
            if(variation===3 && countGuard===1 && modeSec===2 && usluga===2){total.innerHTML='100 500';}
            if(variation===3 && countGuard===1 && modeSec===2 && usluga===3){total.innerHTML='103 700';}
            if(variation===3 && countGuard===1 && modeSec===2 && usluga===4){total.innerHTML='105 200';}
            if(variation===3 && countGuard===2 && modeSec===1 && usluga===1){total.innerHTML='175 000';}
            if(variation===3 && countGuard===2 && modeSec===1 && usluga===2){total.innerHTML='180 250';}
            if(variation===3 && countGuard===2 && modeSec===1 && usluga===3){total.innerHTML='185 700';}
            if(variation===3 && countGuard===2 && modeSec===1 && usluga===4){total.innerHTML='187 200';}
            if(variation===3 && countGuard===2 && modeSec===2 && usluga===1){total.innerHTML='94 500';}
            if(variation===3 && countGuard===2 && modeSec===2 && usluga===2){total.innerHTML='97 300';}
            if(variation===3 && countGuard===2 && modeSec===2 && usluga===3){total.innerHTML='100 255';}
            if(variation===3 && countGuard===2 && modeSec===2 && usluga===4){total.innerHTML='102 500';}
            if(variation===3 && countGuard===3 && modeSec===1 && usluga===1){total.innerHTML='170 000';}
            if(variation===3 && countGuard===3 && modeSec===1 && usluga===2){total.innerHTML='175 200';}
            if(variation===3 && countGuard===3 && modeSec===1 && usluga===3){total.innerHTML='180 200';}
            if(variation===3 && countGuard===3 && modeSec===1 && usluga===4){total.innerHTML='184 500';}
            if(variation===3 && countGuard===3 && modeSec===2 && usluga===1){total.innerHTML='91 700';}
            if(variation===3 && countGuard===3 && modeSec===2 && usluga===2){total.innerHTML='94 500';}
            if(variation===3 && countGuard===3 && modeSec===2 && usluga===3){total.innerHTML='95 200';}
            if(variation===3 && countGuard===3 && modeSec===2 && usluga===4){total.innerHTML='97 800';}

            


            if( countGuard1===1 && usluga1===1){total1.innerHTML='1 050';}
            if( countGuard1===1 && usluga1===2){total1.innerHTML='1 000';}
            if( countGuard1===1 && usluga1===3){total1.innerHTML='950';}
            if( countGuard1===2 && usluga1===1){total1.innerHTML='950';}
            if( countGuard1===2 && usluga1===2){total1.innerHTML='900';}
            if( countGuard1===2 && usluga1===3){total1.innerHTML='800';}
            if( countGuard1===3 && usluga1===1){total1.innerHTML='900';}
            if( countGuard1===3 && usluga1===2){total1.innerHTML='850';}
            if( countGuard1===3 && usluga1===3){total1.innerHTML='750';}

        }
        // usluga
        checkboxes[0].addEventListener('change', ()=>{
            usluga=1;
            Total();
        });
        checkboxes[1].addEventListener('change', ()=>{
            usluga=2;
            Total();
        });
        checkboxes[2].addEventListener('change', ()=>{
            usluga=3;
            Total();
        });
        checkboxes[3].addEventListener('change', ()=>{
            usluga=4;
            Total();
        });


        checkboxes4[0].addEventListener('change', ()=>{
            usluga1=1;
            Total();
        });
        checkboxes4[1].addEventListener('change', ()=>{
            usluga1=2;
            Total();
        });
        checkboxes4[2].addEventListener('change', ()=>{
            usluga1=3;
            Total();
        });

        // countGuard
        checkboxes1[0].addEventListener('change', ()=>{
            countGuard=1;
            Total();
        });
        checkboxes1[1].addEventListener('change', ()=>{
            countGuard=2;
            Total();
        });
        checkboxes1[2].addEventListener('change', ()=>{
            countGuard=3;
            Total();
        });

        checkboxes5[0].addEventListener('change', ()=>{
            countGuard1=1;
            Total();
        });
        checkboxes5[1].addEventListener('change', ()=>{
            countGuard1=2;
            Total();
        });
        checkboxes5[2].addEventListener('change', ()=>{
            countGuard1=3;
            Total();
        });

        // variation
        checkboxes2[0].addEventListener('change', ()=>{
            variation=1;
            Total();
        });
        checkboxes2[1].addEventListener('change', ()=>{
            variation=2;
            Total();
        });
        checkboxes2[2].addEventListener('change', ()=>{
            variation=3;
            Total();
        });

        // modesec
        checkboxes3[0].addEventListener('change', ()=>{
            modeSec=1;
            Total();
        });
        checkboxes3[1].addEventListener('change', ()=>{
            modeSec=2;
            Total();
        });


        checkboxes6[0].addEventListener('change', ()=>{
            modeSec1=1;
            Total();
        });
        checkboxes6[1].addEventListener('change', ()=>{
            modeSec1=2;
            Total();
        });
    };

        
   
    return(
        <div>
            <a name="calc" />
            <div className="CooperationName" ref={refCalc}><span>КАЛЬКУЛЯТОР СТОИМОСТИ</span></div>
            <motion.div  className='calc' animate={calcanimation}>
                <div className="calc1">
                <div className="calclabel">
                <h1 >Выберите вид услуги</h1>
                <div className="servicesblock">
                <button className="CalcPult"><h3>Физическая охрана</h3></button>
                <button className="CalcFiz"><h3>Пультовая охрана</h3></button>
                </div>
                    <div id="D228">
                        <div className="calcblock1">
                        <div className="usluga">
                            <h3>Услуга</h3><br/>
                            <label><input name='cb' type="radio" />Охрана офисов</label><br/>
                            <label><input name='cb' type="radio" />Охрана промышленных предприятий</label><br/>
                            <label><input name='cb' type="radio" />Охрана производственных объектов</label><br/>
                            <label><input name='cb' type="radio" />Охрана стройплощадки</label><br/>
                        </div>
                        <div className="usluga">
                            <h3>Количество постов</h3><br/>
                            <label><input name='pt' type="radio" />1-2 поста</label><br/>
                            <label><input name='pt' type="radio" />3-4 поста</label><br/>
                            <label><input name='pt' type="radio" />5 и более постов</label><br/>
                        </div>
                        </div>
                        <div className="calcblock1">
                        <div className="usluga">
                            <h3>Тип охраны поста</h3><br/>
                            <label><input name='vn' type="radio" />Стационарный в помещении</label><br/>
                            <label><input name='vn' type="radio" />Стационарный уличный</label><br/>
                            <label><input name='vn' type="radio" />Передвижной патруль</label><br/>
                        </div>
                        <div className="usluga">
                            <h3>Режим охраны</h3><br/>
                            <label><input name='ms' type="radio" />Круглосуточный</label><br/>
                            <label><input name='ms' type="radio" />Дневной/ночной 12 часовой</label><br/>
                        </div>
                        </div>


                        <div className="servicesblock servicesblocktotal">
                        <h1>ИТОГО ЗА 1 ПОСТ В МЕСЯЦ</h1>
                        <h3 className='Total'></h3>
                        </div>
                        <div className="Dop">
                            <h6>–расчет является ориентировочным и не является офертой. Для уточнения цены свяжитесь с нами</h6>
                        </div>
                    </div>
                    <div id="D229">
                        <div className="calcblock1">
                        <div className="usluga">
                            <h3>Услуга</h3><br/>
                            <label><input name='cb1' type="radio" />Охранная сигнализация(ОС)</label><br/>
                            <label><input name='cb1' type="radio" />Пожарная сигнализация (ПС)</label><br/>
                            <label><input name='cb1' type="radio" />Кнопка тревожной сигнализации(КТС)</label><br/>
                        </div>
                        <div className="usluga">
                            <h3>Количество объектов охраны</h3><br/>
                            <label><input name='pt1' type="radio" />1-2 объекта</label><br/>
                            <label><input name='pt1' type="radio" />3-4 объекта</label><br/>
                            <label><input name='pt1' type="radio" />5 и более объектов</label><br/>
                        </div>
                        </div>
                        {/* <div className="calcblock1">
                        <div className="usluga">
                            <h3>Режим охраны</h3><br/>
                            <label><input name='ms1' type="radio" />Круглосуточный</label><br/>
                            <label><input name='ms1' type="radio" />Дневной/ночной 12 часовой</label><br/>
                        </div>
                        </div> */}


                        <div className="servicesblock servicesblocktotal">
                        <h1>ИТОГО ЗА 1 ОБЪЕКТ В МЕСЯЦ</h1>
                        <h3 className='Total Total1'></h3>
                        </div>
                        <div className="Dop">
                            <h6>–расчет является ориентировочным и не является офертой. Для уточнения цены свяжитесь с нами</h6>
                        </div>
                    </div>
                </div>
                </div>
            </motion.div>
        </div>
    )
}
