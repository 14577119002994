import React, { useRef } from 'react';
import mountain from '../media/mountain.png'
// import warrior from '../media/warrior.png'


export const Main=()=>{


    return(
        <div className='mainslide'>
            <h1>ВАША БЕЗОПАСНОСТЬ –</h1><span>НАША РАБОТА</span>
            <h4>Добро пожаловать на наш веб-сайт системы безопасности, где мы стремимся предоставить первоклассные решения по безопасности для защиты вашего бизнеса.</h4>
            <a href='#contacts'>СВЯЗАТЬСЯ С НАМИ</a>
            <img class='mountain_main'src={mountain} alt=''/>
        </div>
    )
}
