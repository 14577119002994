import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import cloud from "../media/cloudshield.png";

export const Form=()=>{
    const form= useRef();
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_g4eo3qh', 'template_ybib1id', form.current, 'cMT7jbpxhcUcgCNXo')
        .then((result) => {
            alert("Сообщение отправлено");
        }, (error) => {
            alert("Ошибка!!");
        });
        e.target.reset();
    };
    // function showMassage(){
    //   setDisplayMessage(true)
    // }
  
    // const [displayMessage, setDisplayMessage] = useState(false);
    return(
        <div>
          <a name="contacts"/>
        <div className="PartnersLetterText">
          <img src={cloud} alt=""/>
          <span>КАК С НАМИ СВЯЗАТЬСЯ</span>
        </div>
        <div className="PartnersLetter PartnersLetter1"></div>
        <div className="PartnersLetter PartnersLetter2"></div>
        <div className="PartnersLetter PartnersLetter3"></div>
        <div className="PartnersLetter PartnersLetter4"></div>
        <div className="PartnersLetter PartnersLetter5"></div>
        <div className="PartnersLetter PartnersLetter6"></div>
        <div className="PartnersLetter PartnersLetter7"></div>
        <div className="PartnersLetter">
          <form action="#" className="partners-form" method="get" ref={form} onSubmit={sendEmail}>
            {/* {
              displayMessage ? 
              <div className='showMassage' id='showMassage'>Сообщение отправлено, проверьте почту!</div> 
              : ''
            } */}
            
            <label className="form-control-placeholder" for="name">
              Имя
            </label>
            <input type="text" name="user_name" required/>
            <label className="form-control-placeholder" for="name" >
              e-mail
            </label>
            <input type="email" name="user_mail" required/>
            <label className="form-control-placeholder" for="name">
              Телефон
            </label>
            <input type="tele" name="user_telephone" required/>
            <label className="form-control-placeholder" for="name">
              Компания и интересующая услуга
            </label>
            <input type="text" name="user_company" required/>
            <button type='submit' className="PartnersButton">ОТПРАВИТЬ</button>
          </form>
        </div>
        {/* <button className="PartnersButton">ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ</button> */}
      </div>
    )
}

