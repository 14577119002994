import React from 'react';
import phone from '../media/1.png';
import search from '../media/2.png';
import list from '../media/3.png';
import handsh from '../media/4.png';
import chel from '../media/5.png';


export const Cooperation=()=>{


    return(
        <div className='Cooperation'>
            <div className="CooperationName">
                <div className="palka palkaCoop"/>
                <span>ЭТАПЫ СОТРУДНИЧЕСТВА</span>
                <div className="palka palkaCoop"/>
            </div>

            <div className='stages'>
                <div className="stage">
                    <h1>1</h1>
                    <img src={phone} className="phone" alt=''/>
                    <h6>Вы обращаетесь в нашу компанию любым удобным способом</h6>
                </div>
                <div className="palka palkastages"/>

                <div className="stage">
                    <h1>2</h1>

                    <img src={search} className="search" alt=''/>
                    <h6>Мы осматриваем объект, изучаем его специфику и разрабатываем концепцию безопасности</h6>
                </div>
                <div className="palka palkastages"/>

                <div className="stage">
                    <h1>3</h1>

                    <img src={list} className="list" alt=''/>
                    <h6>Мы выставляем коммерческое предложение, обсуждаем и согласуем его с Вами</h6>
                </div>
                <div className="palka palkastages"/>

                <div className="stage">
                    <h1>4</h1>

                    <img src={handsh} className="handsh" alt=''/>
                    <h6 className="handsh6">Подписываем договор</h6>

                </div>
                <div className="palka palkastages"/>

                <div className="stage">
                    <h1>5</h1>
                    <img src={chel} className="chel" alt=''/>
                    <h6 className="">Мы заступаем на объект в условленный срок</h6>
                </div>
            </div>
        </div>
    )
}
