import React, { useRef } from 'react';




export const Services=()=>{

    const Fiz=useRef();
    const Pult=useRef();
    const Teh=useRef();
    const Econom=useRef();

    // window.onmousemove=function(){
    //     var buttosn1=document.querySelector(".CardServ1");
    //     var buttosn2=document.querySelector(".CardServ2");
    //     var buttosn3=document.querySelector(".CardServ3");
    //     var buttosn4=document.querySelector(".CardServ4");
    //     var href1=document.querySelector(".footerhref1");
    //     var href2=document.querySelector(".footerhref2");
    //     var href3=document.querySelector(".footerhref3");
    //     var href4=document.querySelector(".footerhref4");

    //     var fiz=document.getElementById("Fiz");
    //     var teh=document.getElementById("Teh");
    //     var pult=document.getElementById("Pult");
    //     var econom=document.getElementById("Econom");
    const fizClick = () => {
        Fiz.current.style.display="block";
        Fiz.current.style.animationName="Show_Des";
        Fiz.current.style.animationDuration="2s";
        Pult.current.style.display="none";
        Teh.current.style.display="none";
        Econom.current.style.display="none";
        console.log(1)
    }
    const tehClick = () => {
        Teh.current.style.display="block";
        Teh.current.style.animationName="Show_Des";
        Teh.current.style.animationDuration="2s";
        Pult.current.style.display="none";
        Fiz.current.style.display="none";
        Econom.current.style.display="none";
    }
    const pultClick = () => {
        Pult.current.style.display="block";
        Pult.current.style.animationName="Show_Des";
        Pult.current.style.animationDuration="2s";
        Teh.current.style.display="none";
        Fiz.current.style.display="none";
        Econom.current.style.display="none";
    }
    const economClick = () => {
        Econom.current.style.display="block";
        Econom.current.style.animationName="Show_Des";
        Econom.current.style.animationDuration="2s";
        Teh.current.style.display="none";
        Fiz.current.style.display="none";
        Pult.current.style.display="none";
    }



        // CardServ1.addEventListener('click', ()=>{
        //     console.log(122)
            // fiz.style.display='block';
            // fiz.style.animationName="Show_Des";
            // fiz.style.animationDuration="2s";
            // pult.style.display="none";
            // teh.style.display="none";
            // econom.style.display="none";
        // });
        // href1.addEventListener('click', ()=>{
        //     fiz.style.display='block';
        //     fiz.style.animationName="Show_Des";
        //     fiz.style.animationDuration="2s";
        //     pult.style.display="none";
        //     teh.style.display="none";
        //     econom.style.display="none";
        // });

    //     buttosn2.addEventListener('click', ()=>{
    //         teh.style.display='block';
    //         teh.style.animationName="Show_Des";
    //         teh.style.animationDuration="2s";
    //         pult.style.display="none";
    //         fiz.style.display="none";
    //         econom.style.display="none";
    //     });
    //     href2.addEventListener('click', ()=>{
    //         teh.style.display='block';
    //         teh.style.animationName="Show_Des";
    //         teh.style.animationDuration="2s";
    //         pult.style.display="none";
    //         fiz.style.display="none";
    //         econom.style.display="none";
    //     });

    //     buttosn3.addEventListener('click', ()=>{
    //         pult.style.display='block';
    //         pult.style.animationName="Show_Des";
    //         pult.style.animationDuration="2s";
    //         teh.style.display="none";
    //         fiz.style.display="none";
    //         econom.style.display="none";
    //     });
    //     href3.addEventListener('click', ()=>{
    //         pult.style.display='block';
    //         pult.style.animationName="Show_Des";
    //         pult.style.animationDuration="2s";
    //         teh.style.display="none";
    //         fiz.style.display="none";
    //         econom.style.display="none";
    //     });

    //     buttosn4.addEventListener('click', ()=>{
    //         econom.style.display='block';
    //         econom.style.animationName="Show_Des";
    //         econom.style.animationDuration="2s";
    //         teh.style.display="none";
    //         fiz.style.display="none";
    //         pult.style.display="none";
    //     });
    //     href4.addEventListener('click', ()=>{
    //         econom.style.display='block';
    //         econom.style.animationName="Show_Des";
    //         econom.style.animationDuration="2s";
    //         teh.style.display="none";
    //         fiz.style.display="none";
    //         pult.style.display="none";
    //     });
    // }
        // buttosn1.addEventListener('click', ()=>{
        //     pult.style.display='block';

        // });



    return(
        <div className='services'>
            <a name='services'/>
            <h4 className='uslugi'>НАШИ УСЛУГИ</h4>
            <div className='Cards'>
                <div onClick={fizClick} className='CardServ CardServ1'>
                <i class="fa-solid fa-user-tie"></i>                    
                <h4>ФИЗИЧЕСКАЯ ОХРАНА</h4>
                    <button className='buttosn1'>Подробнее</button>
                </div>
                {/* <div onClick={tehClick} className='CardServ CardServ2'>
                <i class="fa-solid fa-tower-broadcast"></i>
                    <h4>ТЕХНИЧЕСКИЕ СРЕДСТВА БЕЗОПАСНОСТИ</h4>
                    <button className='buttosn2'>Подробнее</button>
                </div> */}
                <div onClick={pultClick} className='CardServ CardServ3'>
                <i class="fa-solid fa-computer"></i>
                    <h4>ПУЛЬТОВАЯ ОХРАНА</h4>
                <button className='buttosn3'>Подробнее</button>
                </div>
                <div onClick={economClick} className='CardServ CardServ4'>
                <i class="fa-solid fa-comments-dollar"></i>
                    <h4>ЭКОНОМИЧЕСКАЯ БЕЗОПАСНОСТЬ ОРГАНИЗАЦИЙ</h4>
                    <button className='buttosn4'>Подробнее</button>
                </div>
                <a name="Fiz"/>
                <a name="Teh"/>
                <a name="Pult"/>
                <a name="Econom"/>

            </div>
            <div id='Fiz' ref={Fiz}>
                <h2>ФИЗИЧЕСКАЯ ОХРАНА</h2>
            {/* <div className="servicesblock fizblock">
                    <h3>один круглосуточный пост, один сотрудник охраны</h3>
                    <h3>один дневной/ночной 12-часовой пост, один сотрудник охраны</h3>

                </div> */}
                <h5 className='Servicesh4'>Физическая охрана объектов представляет собой комплекс мероприятий, которые направлены на обеспечение защиты и полную сохранность материальных ценностей и имущества:<br/>
- офисов<br/>
- промышленных предприятий<br/>
- производственных объектов<br/>
- строительных площадок<br/>
- массовых мероприятий<br/>
- сопровождение и охрана грузов<br/>
<br/>
<p>
Диспетчерская служба пульта централизованного наблюдения осуществляет круглосуточный мониторинг обстановки на вверенных объектах, ведет контроль работы персонала.
</p>
<p>Группа быстрого реагирования (ГБР) высококвалифицированные специалисты которой обладают безупречными навыками работы в экстремальных ситуациях.
</p>
</h5>
                <h2 className='Rates'>НАШИ ТАРИФЫ</h2>
                <div className='ServicePrice ServicePricefiz'>
                    <div className="PriceBlock">
                        <div className="PriceName">КРУГЛОСУТОЧНЫЙ ПОСТ</div>
                        <div className="PriceTriangle PriceTrianglefiz"></div>

                        <h4 className='Price'>от 160 000 руб./в месяц</h4>
                        <a href='#contacts'>Заказать</a>
                    </div>
                    <div className="PriceBlock">
                        <div className="PriceName">ДНЕВНОЙ /НОЧНОЙ 12-ЧАСОВОЙ ПОСТ</div>
                        <div className="PriceTriangle PriceTrianglefiz"></div>

                        <h4 className='Price'>от 80 000 руб./в месяц</h4>
                        <a href='#contacts'>Заказать</a>
                    </div>
                    </div>
                <div className="Dop">
                    <h6>–Цены на услуги указаны без учета НДС</h6>
                    <h6>–Окончательная стоимость зависит от конкретных условий охраны объекта</h6>
                    <h6>–Стоимость услуг охраны за месяц может быть фиксированной или рассчитана по формуле = Тариф х Часы.</h6>
                </div>
            </div>
            {/*блок с описанием тех охраны*/}
            <div id='Teh' ref={Teh}>

            <h2>ТЕХНИЧЕСКИЕ СРЕДСТВА БЕЗОПАСНОСТИ</h2>
            <div className="servicesblock tehblock">
                    <h3>монтаж, техническое сопровождение и обслуживание систем охранной и пожарной сигнализации</h3>
                    <h3>монтаж, техническое сопровождение и обслуживание систем видеонаблюдения</h3>
                    <h3>монтаж структурированных кабельных систем</h3>
                    <h3>монтаж, техническое сопровождение и обслуживание систем оповещения и управления эвакуацией</h3>
                    <h3>монтаж, техническое сопровождение и обслуживание систем контроля и управления доступом</h3>
                    <h3>монтажные работы прочих слаботочных систем</h3>

                </div>
                <h2 className='Rates'>НАШИ ТАРИФЫ</h2>
                <h5 className='Servicesh4'>Стоимость услуги монтажа систем безопасности рассчитывается индивидуально и зависит от площади объекта, количества единиц оборудования, сложности работ и особенностей проекта.<p>Специалисты компании ответят на ваши вопросы, произведут расчет стоимости услуг и подготовят индивидуальное коммерческое предложение.</p></h5>
                <a href='#contacts'>ЗАКАЗАТЬ</a>
            </div>
            <div id='Pult' ref={Pult}>

                <h2 >ПУЛЬТОВАЯ ОХРАНА</h2>
                <div className="servicesblock">
                    <h3>охранная сигнализация</h3>
                    <h3>кнопка тревожной сигнализации</h3>
                    <h3>пожарная сигнализация</h3>
                </div>
                <h5 className='Servicesh4'>Пультовая охрана - электронное оборудование для мониторинга объектов, включающее датчики, камеры и кнопки вызова. Она позволяет осуществлять круглосуточный мониторинг без присутствия охранников на месте. В случае получения тревожного сигнала, сотрудники группы экстренного реагирования прибывают на место происшествия и задерживают нарушителей. Пультовая охрана устанавливается на различных объектах и обеспечивает высокую безопасность при приемлемой стоимости.</h5>
                <h2 className='Rates'>НАШИ ТАРИФЫ</h2>
                <div className='ServicePrice'>
                    <div className="PriceBlock">
                        <div className="PriceName">ОХРАННАЯ СИГНАЛИЗАЦИЯ</div>
                        <div className="PriceTriangle"></div>

                        <h4 className='Price'>от 700 руб./в месяц</h4>
                        <a href='#contacts'>Заказать</a>
                    </div>
                    <div className="PriceBlock">
                        <div className="PriceName">КНОПКА ТРЕВОЖНОЙ СИГНАЛИЗАЦИИ</div>
                        <div className="PriceTriangle"></div>

                        <h4 className='Price'>от 700 руб./в месяц</h4>
                        <a href='#contacts'>Заказать</a>
                    </div>
                    <div className="PriceBlock">
                        <div className="PriceName">ПОЖАРНАЯ СИГНАЛИЗАЦИЯ</div>
                        <div className="PriceTriangle"></div>
                        
                        <h4 className='Price'>от 700 руб./в месяц</h4>
                        <a href='#contacts'>Заказать</a>
                    </div>
                    <div className="PriceBlock">
                        <div className="PriceName"> НЕОБХОДИМОЕ ОБОРУДОВАНИЕ</div>
                        <div className="PriceTriangle"></div>

                        <h4 className='Price'>от 9000 руб.</h4>
                        <a href='#contacts'>Заказать</a>
                    </div>
                    </div>
                <div className="Dop">
                    <h6>–Монтаж оборудования - БЕСПЛАТНО.</h6>
                    <h6>–Предоставляется возможность аренды оборудования или приобретение в рассрочку</h6>
                    <h6>–Окончательная стоимость зависит от конкретных условий охраны объекта</h6>
                </div>
            </div>
            <div id='Econom' ref={Econom}>

            <h2>ЭКОНОМИЧЕСКАЯ БЕЗОПАСНОСТЬ ОРГАНИЗАЦИЙ</h2>
            {/* <div className="servicesblock">
                <h3>охранная сигнализация</h3>
                <h3>кнопка тревожной сигнализации</h3>
                <h3>пожарная сигнализация</h3>
            </div>
            <div className="servicesblock tehblock economblock">
                    <h3>монтаж, техническое сопровождение и обслуживание систем пожарной безопасности</h3>
                </div> */}
                <h5 className="Servicesh4">Экономическая безопасность организаций является ключевой составляющей успеха ведения бизнеса в наше время. Обеспечение безопасности экономических процессов способствует защите имущества и бизнеса от нечестных действий сотрудников, контрагентов и других сторон. Аудит, проверка контрагентов, бизнес-процессов и внутренних расследований являются неотъемлемыми элементами обеспечения экономической безопасности организаций. Проявление максимальной внимательности при раскрытии и устранении рисков благотворно воздействует на долгосрочную успешность компании.</h5>
                <h2 className='Rates'>НАШИ ТАРИФЫ</h2>
                <h5 className='Servicesh4'>Стоимость услуги рассчитывается индивидуально и зависит от многих фактов. Специалисты компании ответят на ваши вопросы, произведут расчет стоимости услуг и подготовят индивидуальное коммерческое предложение.</h5>
                <a href='#contacts'>ЗАКАЗАТЬ</a>
            </div>
    </div>
        
    )
}
export function fizclick(){

}