import React from 'react';


export const Vacations=()=>{

    return(
        <div className='vacation'>
            <div className="vacation1">
            <a name="vacations"/>
            <div className="licensevac">
            <h4>В ООО ЧОП "Олимп" требуются:</h4>
            </div>
            <div className="servicesblock servicesblockvac">
            <h3><i class="fa-solid fa-user-shield"></i>Охранники 4 разряда</h3>
            <h3><i class="fa-solid fa-person-military-rifle"></i>Сотрудники ГБР</h3>
            {/* <h3><i class="fa-solid fa-pen-ruler"></i>Инженер-проектировщик<p>слаботочных систем</p></h3> */}
            </div>
            </div>
            <div className="vacation2">
            <h5>По всем вопросам трудоустройства обращаться по телефону:</h5>
            <br/>
            <h5 className='f3'><i class="fa-solid fa-phone"></i> +7 (8553) 44-19-09</h5>
            <h5 className='f3'><i class="fa-solid fa-phone"></i> +7 962 569-61-62</h5>
            </div>
        </div>
    )
}