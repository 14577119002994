import React, { useRef } from 'react';
import shield from '../media/shield.png';
import cloud from '../media/cloudshield.png';
import cloud2 from '../media/cloudshield2.png';
import { motion} from "framer-motion";
// import { useInView } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';



export const Achievements=()=>{
    
    // const reff = useRef();
    // const inView=useInView(reff,{ once:true });
    const [refcloud,isinViewCloud]=useInView({
        threshold:1
    })
    const [ref,isinView]=useInView({
        threshold:0.4
    })

    // const refff = useRef()
    // const isinView=useInView(refff,{ once:true });

    const animation=useAnimation();
    const cloudanimation=useAnimation();
    const cloudanimation2=useAnimation();

    useEffect(()=>{
        if(isinViewCloud){
            cloudanimation2.start({
                animation: {name:"CloudShield2", duration:"1.5s", type:"ease-in-out"},
                display:"block",
                transitionDelay:"2s"
            })
        }
    },[isinViewCloud])

    useEffect(()=> {
        if(isinView){
            animation.start({
                animationName:"left_table",
                animationDuration:"1.6s",
                display:"block"
            });
            cloudanimation.start({
                animation: {name:"CloudShield", duration:"1.5s", type:"ease-in-out"},
                display:"block",
            })
        }
    },[isinView])
    

    // const blockAnimation ={
    //     hidden:{
    //         x:-100,
    //         opacity:0
    //     },
    //     visible:{
    //         x:0,
    //         opacity:1
    //     }
    // }

    return(
        <div className='achievementslide'>
            <div className='description dis1'>
                <motion.h3 animate={animation} className='qwe qzz' id='des'>СВИДЕТЕЛЬСТВО О ВНЕСЕНИИ В НАЦИОНАЛЬНЫЙ РЕЕСТР НАДЕЖНЫХ ПОСТАВЩИКОВ</motion.h3>
                <motion.h3 animate={animation} className='ewq qzz' id='des1'>ШИРОКАЯ ГЕОГРАФИЯ ПРИСУТСТВИЯ</motion.h3>
                <motion.h3 animate={animation} className='ew qzz' id='des2'>СЕРТИФИКАТ СООТВЕТСТВИЯ СИСТЕМЕ ДОБРОВОЛЬНОЙ СЕРТИФИКАЦИИ</motion.h3>
            </div>
            <div className='shield'>
                <img src={shield}ref={ref} alt=''/>
            </div>
            <div className='description dis2' >
                <motion.h3 animate={animation} className='qwe zzq' id='des3'>СЕРТИФИКАТ СООТВЕТСТВИЯ ТРЕБОВАНИЯМ:<font> ISO 9001, 14001, OHSAS 18001</font></motion.h3>
                <motion.h3 animate={animation} className='ewq qq zzq' id='des4'>БОЛЕЕ <font>280</font> СОТРУДНИКОВ</motion.h3>
                <motion.h3 ref={refcloud} animate={animation} className='ew zzq' id='des5'>НАЛИЧИЕ ЛИЦЕНЗИЙ И РАЗРЕШИТЕЛЬНОЙ ДОКУМЕНТАЦИИ</motion.h3>
            </div>
            <motion.img animate={cloudanimation} className='CloudShield' src={cloud} alt=""/>
            <motion.img  className='CloudShield2' animate={cloudanimation2} src={cloud2} alt=""/>
        </div>
    )
}
// variants={{ hidden: {opacity: 0,right:"-20%", display:"none"}, visible: {opacity: 1,right:"0%", display:"block"} }} initial="hidden" animate="visible"