import React, { useRef } from 'react';


export const Prom=()=>{


    return(<div>
                        <a name='prom'/>

        <h1 className='promname'>Акции</h1>
        <div className='prom'>
        <div className='promsale'>
            <h1>АКЦИЯ 11+1</h1>
            <h3>Для обеспечения максимальной защиты офиса и любых других коммерческих объектов, подключите систему безопасности ООО ЧОП "ОЛИМП"</h3>
            <h5>Единовременно оплачивая услуги 11 месяцев вперед, Вы получаете один месяц абонентской платы в подарок!</h5>
            <h5>моб.:8-962-569-61-62, Офис:(8553) 44-19-09 г.Альметьевск, проспект Габдуллы Тукая, 41Б</h5>
            
        </div>
        
        <div className='promsale'>
            <h1>Бесплатное поключение</h1>
            <h3>Если у вас уже установлено оборудовнаие другой охранной фирмы, но вы недовольны качеством предоставленных услуг, то мы готовы подключить вас на наш охранный пульт бесплатно (при наличии технической возможности)</h3>
            <h5>моб.:8-962-569-61-62, Офис:(8553) 44-19-09 г.Альметьевск, проспект Габдуллы Тукая, 41Б</h5>
            
        </div>

        <div className='promsale'>
            <h1>АКЦИЯ "ПРИВЕДИ ДРУГА"</h1>
            <h3>Приведи друга в ООО ЧОП "ОЛИМП" для заключения договора охраны и получите 3 месяца охраны бесплатно</h3>
            <h5>*при этом ваш друг получит скидку на монтаж охранной системы</h5>
            <h5>моб.:8-962-569-61-62, Офис:(8553) 44-19-09 г.Альметьевск, проспект Габдуллы Тукая, 41Б</h5>
            
        </div>
        
        </div>
        </div>
    )
}
