
import React from 'react';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { About } from './components/About';
import { Achievements } from './components/Achievements';
import { Services } from './components/Services';
import { Cooperation } from './components/Cooperation';
import { Carousel } from './components/Carousel';
import { Footer } from './components/Footer';
import { Form } from './components/Form';
import { Calc } from './components/Calc';
import { Vacations } from './components/Vacations';
import { Prom } from './components/Prom';


function App() {
  return (
    <div>
    <Header/>
    <Main/>
    <About/>
    <Achievements/>
    <Services/>
    <Cooperation/>
    <Carousel/>
    <Vacations/>
    <Prom/>
    <Calc/>
    <Form/>
    <Footer/>
    </div>
  );
}

export default App;
