import React from 'react';
import license1 from "../media/license1.png";
import license2 from "../media/license2.png";
import license3 from "../media/license3.png";


export const Carousel=()=>{

    var html = document.documentElement, body = document.body;

    html.onanimationstart=function(){
        var Fiz=document.getElementById("Fiz");
        var Teh=document.getElementById("Teh");
        var Pult=document.getElementById("Pult");
        var Econom=document.getElementById("Econom");

        var href1=document.querySelector(".footerhref1");
        var href3=document.querySelector(".footerhref3");
        var href4=document.querySelector(".footerhref4");
        href1.addEventListener('click', ()=>{
            Fiz.style.display="block";
            Fiz.style.animationName="Show_Des";
            Fiz.style.animationDuration="2s";
            Pult.style.display="none";
            Teh.style.display="none";
            Econom.style.display="none";
        })
        href3.addEventListener('click', ()=>{
            Pult.style.display="block";
            Pult.style.animationName="Show_Des";
            Pult.style.animationDuration="2s";
            Teh.style.display="none";
            Fiz.style.display="none";
            Econom.style.display="none";
        })
        href4.addEventListener('click', ()=>{
            Econom.style.display="block";
            Econom.style.animationName="Show_Des";
            Econom.style.animationDuration="2s";
            Teh.style.display="none";
            Fiz.style.display="none";
            Pult.style.display="none";
        })



        var carouselSlide=document.querySelector(".CarouselSlides");
        var carouselContainer=document.querySelector(".CarouselContainer");
        var carouselImages=document.querySelectorAll(".CarouselSlides img");
    
        var prevBtn =document.querySelector("#prevBtn");
        var nextBtn =document.querySelector("#nextBtn");
    
        var counter=1;
        var size= carouselContainer.clientWidth;
        carouselSlide.style.transform="translateX("+(-size*counter)+"px)";

        nextBtn.addEventListener('click', ()=>{
            if(counter>=carouselImages.length-1) return;
            carouselSlide.style.transition=" 0.7s ease-in-out";
            counter++;
            carouselSlide.style.transform="translateX("+(-size*counter)+"px)";
        });
        prevBtn.addEventListener('click', ()=>{
            if(counter<=0) return;
            carouselSlide.style.transition=" 0.7s ease-in-out";
            counter--;
            carouselSlide.style.transform="translateX("+(-size*counter)+"px)";
        });

        carouselSlide.addEventListener('transitionend',()=>{
            if(carouselImages[counter].id==='lastClone'){
                carouselSlide.style.transition="none";
                counter=carouselImages.length-2;
                carouselSlide.style.transform="translateX("+(-size*counter)+"px)";

            }
            if(carouselImages[counter].id==='firstClone'){
                carouselSlide.style.transition="none";
                counter=carouselImages.length-counter;
                carouselSlide.style.transform="translateX("+(-size*counter)+"px)";

            }
        })
        };
   

    return(
        <div className='license'>
            <div className="LicenseDes">
                <h4>ЛИЦЕНЗИИ</h4>
                <h5>Мы понимаем важность поддержания прозрачности и соблюдения всех соответствующих требований законодательства. Имея это в виду, мы получили все необходимые лицензии и сертификаты для ведения нашего бизнеса в соответствии с законами.</h5>
            </div>
            <button id="prevBtn"><i class="fa-solid fa-chevron-left"></i></button>
            <div className="CarouselContainer">
                <div className="CarouselSlides">
                        <img src={license3} className="license4" id="lastClone" alt="" />
                        <img src={license1} className="license1" alt="" />
                        <img src={license2} className="license2" alt="" />
                        <img src={license3} className="license3" alt="" />
                        <img src={license1} className="license1" id="firstClone" alt="" />
                </div>
            </div>
            <button id="nextBtn" ><i class="fa-solid fa-chevron-right"></i></button>
        </div>
    )
}